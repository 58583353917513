import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBatches(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt
          .getData('tracking/batch/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBatch(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt
          .getData(`tracking/detail/batch/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
